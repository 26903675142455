const burger = document.querySelector(".burger");
const burgerMenu = document.querySelector(".burger-menu");
const body = document.querySelector("body");

burger.addEventListener("click", () => {
  burger.classList.toggle("active");
  burgerMenu.classList.toggle("open");
  body.classList.toggle("active");
});

const links = document.querySelectorAll("a");

links.forEach((link) => {
  if (!link.getAttribute("href").startsWith("#")) return;

  link.addEventListener("click", (e) => {
    e.preventDefault();
    const target = document.querySelector(link.getAttribute("href"));
    target.scrollIntoView({
      behavior: "smooth",
    });
  });
});

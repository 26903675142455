const form = document.querySelector(".form");

const onClear = () => {
  form.querySelector("#name").value = "";
  form.querySelector("#phone").value = "";
  form.querySelector("#mail").value = "";
  form.querySelector("#classname").value = "";
  form.querySelector("#textarea").value = "";
};

form.addEventListener("submit", async (e) => {
  e.preventDefault();

  const data = new FormData(e.target);

  const getFormData = (object) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  const body = getFormData({
    name: data.get("name"),
    phone: data.get("phone"),
    mail: data.get("mail"),
    classname: data.get("classname"),
    textarea: data.get("textarea"),
  });

  try {
    await fetch("/server/mail.php", {
      method: "POST",
      body,
    });
  } catch (e) {
  } finally {
    const w = document.querySelector("#pop-up-sent");
    w.classList.add("visible");

    const r = document.querySelector("#pop-up-wrapper");
    r.classList.remove("visible");
    onClear();
  }
});

//---//

const form2 = document.querySelector("#form-two");

const onClear2 = () => {
  form2.querySelector("#name2").value = "";
  form2.querySelector("#phone2").value = "";
  form2.querySelector("#mail2").value = "";
  form2.querySelector("#classname2").value = "";
  form2.querySelector("#textarea2").value = "";
};

form2.addEventListener("submit", async (e) => {
  e.preventDefault();

  const data = new FormData(e.target);

  const getFormData = (object) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  const body = getFormData({
    name: data.get("name"),
    phone: data.get("phone"),
    mail: data.get("mail"),
    classname: data.get("classname"),
    textarea: data.get("textarea"),
  });

  try {
    await fetch("/server/mail2.php", {
      method: "POST",
      body,
    });
  } catch (e) {
  } finally {
    const w = document.querySelector("#pop-up-sent");
    w.classList.add("visible");

    const r = document.querySelector("#pop-up-wrapper2");
    r.classList.remove("visible");
    onClear2();
  }
});

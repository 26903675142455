new Swiper(".swiper-advantages", {
  direction: "horizontal",
  loop: true,

  navigation: {
    nextEl: ".swiper-advantages .swiper-button-next",
    prevEl: ".swiper-advantages .swiper-button-prev",
  },

  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },

  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 24,
    },

    968: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
  },
});

new Swiper(".swiper-latest-publications-mobile", {
  direction: "horizontal",
  loop: true,

  pagination: {
    el: ".swiper-latest-publications-mobile .swiper-pagination",
    type: "bullets",
    clickable: true,
  },

  breakpoints: {
    320: {
      slidesPerView: 1.1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
  },
});
